
import {
  defineComponent,
  computed,
  onMounted,
  inject,
  ref,
  reactive,
} from 'vue';
import { useStore } from 'vuex';
import TwitchUser from '../test-forms/components/TwitchUser.vue';
import _ from 'lodash';

import Prism from 'vue-prism-component';

import {
  TauStatusWsService,
  TauTwitchEventWsService,
} from '@/services/tau-api-ws';

import { TwitchEvent, eventTitleMap } from '@/models/twitch-event';
import { Broadcaster } from '@/models/broadcaster';
import { Streamer } from '@/models/streamer';

export default defineComponent({
  name: 'Streamers',
  components: { Prism, TwitchUser },
  setup() {
    const store = useStore();

    const data = computed(() => {
      return store.getters['streamers/all'] as Streamer[];
    });

    const displayAddModal = ref(false);

    const newStreamer = reactive({
      user_id: '',
      user_name: '',
      user_login: '',
    });

    const broadcaster = computed<Broadcaster>(() => {
      return store.getters['broadcaster/data'];
    });

    const twitchEvents = computed(() => {
      return store.getters['twitchEvents/all'];
    });

    const fetchEventSubscriptions = async () => {
      await store.dispatch('twitchEvents/loadAll');
      await store.dispatch('streamers/loadAll');
      await store.dispatch('broadcaster/load');
      tauStatusWs.connect();
      twitchEventWs.connect();
    };

    const tauStatusWs = inject('tauStatusWs') as TauStatusWsService;
    const twitchEventWs = inject('twitchEventWs') as TauTwitchEventWsService;

    onMounted(fetchEventSubscriptions);

    function closeAddForm() {
      displayAddModal.value = false;
      newStreamer.user_id = '';
      newStreamer.user_name = '';
      newStreamer.user_login = '';
    }

    function submitAddForm() {
      // do something
      const payload: Streamer = {
        twitch_username: newStreamer.user_name,
        disabled: false,
        streaming: false,
      };
      store.dispatch('streamers/create', payload);
      closeAddForm();
    }

    function addStreamer() {
      console.log('add streamer!');
      displayAddModal.value = true;
    }

    function deleteStreamer(streamer: Streamer) {
      console.log('delete streamer!', streamer);
      store.dispatch('streamers/delete', streamer);
    }

    function twitchEventTitle(twitchEvent: TwitchEvent) {
      const msgSource =
        twitchEvent.origin === 'replay'
          ? '[Replay] '
          : twitchEvent.origin === 'test'
          ? '[Test] '
          : '';
      return twitchEvent.event_type in eventTitleMap
        ? msgSource + eventTitleMap[twitchEvent.event_type](twitchEvent)
        : msgSource + eventTitleMap['default'](twitchEvent);
    }

    function replay(twitchEvent: TwitchEvent) {
      store.dispatch('twitchEvents/replay', twitchEvent);
    }

    return {
      data,
      broadcaster,
      twitchEvents,
      twitchEventTitle,
      replay,
      addStreamer,
      displayAddModal,
      newStreamer,
      closeAddForm,
      submitAddForm,
      deleteStreamer,
    };
  },
});
